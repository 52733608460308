import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Header from "./components/header/Header";
import NavSection from "./components/navbar/NavSection";
import BodySection from "./components/BodySection";
import Footer from "./components/footer/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoToTop from "./components/GoToTop";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <GoToTop />
        <NavSection />
        <BodySection />
        <Footer />
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
