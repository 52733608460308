// import React, { useState } from "react";

// const AnnouncementCard = ({ announcement }) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const truncateText = (text, limit) => {
//     if (text.length > limit) {
//       return text.substring(0, limit) + "...";
//     }
//     return text;
//   };

//   const handleLearnMore = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   const imageUrl = `${process.env.REACT_APP_API_URL}/events/${announcement.id}/image`;

//   return (
//     <div className="announcement-container">
//       <div className="announcement-card">
//         <div className="announcement-card1">
//           <img
//             src={imageUrl}
//             alt={announcement.heading}
//             className="announcement-image"
//           />
//         </div>
//         <div className="announcement-card2">
//           <p className="announcement-date">{announcement.date}</p>
//           <h3 className="announcement-title">{announcement.heading}</h3>
//           <p className="announcement-description">
//             {truncateText(announcement.description, 150)}
//           </p>
//           <button className="learn-more-btn" onClick={handleLearnMore}>
//             <span className="circle" aria-hidden="true">
//               <span className="icon arrow"></span>
//             </span>
//             <span className="button-text">Learn More</span>
//           </button>
//         </div>
//       </div>

//       {/* Modal Popup */}
//       {isModalOpen && (
//         <div className="modal-overlay" onClick={closeModal}>
//           <div className="modal-content" onClick={(e) => e.stopPropagation()}>
//             <div className="modal-content1">
//               <img
//                 src={imageUrl}
//                 alt={announcement.heading}
//                 className="modal-image"
//               />
//             </div>
//             <div className="modal-content2">
//               <h2 className="modal-title">{announcement.heading}</h2>
//               <p className="modal-event">Event Description</p>
//               <p className="modal-description">{announcement.description}</p>
//               <button className="close-btn" onClick={closeModal}>
//                 Close
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AnnouncementCard;

import React, { useState, useEffect } from "react";

const AnnouncementCard = ({ announcement }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventImages, setEventImages] = useState([]);

  const fetchEventImages = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/event-images/event/${announcement.id}`
      );
      const data = await response.json();
      console.log("API Response:", data);

      // Convert buffer to Base64 safely
      const processedData = data.map((item) => {
        const binaryString = Array.from(new Uint8Array(item.image.data))
          .map((byte) => String.fromCharCode(byte))
          .join("");
        const base64Image = `data:image/jpeg;base64,${btoa(binaryString)}`;

        return { ...item, base64Image };
      });
      console.log("Processed Data:", processedData);

      setEventImages(processedData);
    } catch (error) {
      console.error("Error fetching event images:", error);
    }
  };

  const handleLearnMore = () => {
    fetchEventImages();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEventImages([]);
  };

  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return text.substring(0, limit) + "...";
    }
    return text;
  };

  const imageUrl = `${process.env.REACT_APP_API_URL}/events/${announcement.id}/image`;

  return (
    <div className="announcement-container">
      <div className="announcement-card">
        <div className="announcement-card1">
          <img
            src={imageUrl}
            alt={announcement.heading}
            className="announcement-image"
          />
        </div>
        <div className="announcement-card2">
          <p className="announcement-date">{announcement.date}</p>
          <h3 className="announcement-title">{announcement.heading}</h3>
          <p className="announcement-description">
            {truncateText(announcement.description, 150)}
          </p>
          <button className="learn-more-btn" onClick={handleLearnMore}>
            <span className="circle" aria-hidden="true">
              <span className="icon arrow"></span>
            </span>
            <span className="button-text">Learn More</span>
          </button>
        </div>
      </div>

      {/* Modal Popup */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content1">
              <img
                src={imageUrl}
                alt={announcement.heading}
                className="modal-image"
              />
            </div>
            <div className="modal-content2">
              <h2 className="modal-title">{announcement.heading}</h2>
              <p className="modal-event">Event Description</p>
              <p className="modal-description">{announcement.description}</p>

              {/* Image Section */}
              <div className="modal-image-section">
                <p className="image-gallery-title">Event Images</p>
                <div className="modal-image-gallery">
                  {eventImages.length > 0 ? (
                    eventImages.map((image, index) => (
                      <div key={index} className="image-container">
                        <img
                          src={image.base64Image} // Use the Base64 image data
                          alt={`Event ${index + 1}`}
                          className="modal-image"
                        />
                      </div>
                    ))
                  ) : (
                    <p>Loading ...</p>
                  )}
                </div>
              </div>

              <button className="close-btn" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnnouncementCard;
