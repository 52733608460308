import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

const Footer = () => {
  const socialLinks = [
    {
      name: "Facebook",
      href: "https://facebook.com/",
      svgPath:
        "M8 0C3.58 0 0 3.58 0 8c0 4.42 3.58 8 8 8s8-3.58 8-8c0-4.42-3.58-8-8-8zm2.28 8.66h-1.61v5.34H7.12V8.66H5.81V7.12h1.31V5.64c0-1.14.6-2.85 2.85-2.85h1.32v1.54h-.96c-.59 0-.71.28-.71.69v1.1h1.64l-.21 1.54z",
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/",
      svgPath:
        "M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334",
    },
    {
      name: "YouTube",
      href: "https://youtube.com/",
      svgPath:
        "M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z",
    },
  ];
  return (
    <>
      <div className="footer">
        <div className="footer-content">
          <div className="footer-links">
            <div className="footer-left">
              <div className="footer-logo">
                <Link to="/">
                  <h2>ARON ENGINEERING</h2>
                </Link>
              </div>
              <div className="company-info">
                <p>KATHMANDU, NEPAL</p>

                <div className="social">
                  <ul className="example-2">
                    {socialLinks.map((link, index) => (
                      <li key={index} className="icon-content">
                        <a
                          data-social={link.name.toLowerCase()}
                          aria-label={link.name}
                          href={link.href}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="filled"></div>
                          <svg
                            xmlSpace="preserve"
                            viewBox="0 0 16 16"
                            className={`bi bi-${link.name.toLowerCase()}`}
                            fill="currentColor"
                            height="16"
                            width="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path fill="currentColor" d={link.svgPath}></path>
                          </svg>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-midA">
              <h1>LINKS</h1>

              <p className="footer-link">
                <div class="vertical-line"></div>
                <Link to="/">Home</Link>
              </p>
              <p className="footer-link">
                <div class="vertical-line"></div>
                <Link to="/portfolio">Portfolio</Link>
              </p>
              <p className="footer-link">
                <div class="vertical-line"></div>
                <Link to="/services">Services</Link>
              </p>
              <p className="footer-link">
                <div class="vertical-line"></div>
                <Link to="/contact">Contact Us</Link>
              </p>
            </div>
            <div className="footer-midB">
              <h1>SERVICES</h1>

              <p className="footer-link">
                <div class="vertical-line"></div>
                <a href="/services">Engineering</a>
              </p>
              <p className="footer-link">
                <div class="vertical-line"></div>
                <a href="/services">Construction</a>
              </p>
              <p className="footer-link">
                <div class="vertical-line"></div>
                <a href="/services">Procurement</a>
              </p>
              <p className="footer-link">
                <div class="vertical-line"></div>
                <a href="/services">Development</a>
              </p>
            </div>
            <div className="footer-right">
              <h1>CAREERS</h1>

              <p className="footer-link">
                <div class="vertical-line"></div>
                <a href="/announcements">Entry Level</a>
              </p>
              <p className="footer-link">
                <div class="vertical-line"></div>
                <a href="/announcements">Internships</a>
              </p>
              <p className="footer-link">
                <div class="vertical-line"></div>
                <a href="/announcements">Skilled Craft</a>
              </p>
              <p className="footer-link">
                <div class="vertical-line"></div>
                <a href="/announcements">Professionals</a>
              </p>
            </div>
          </div>
          <div className="footer-copyright">
            <p>
              &#169; ARON ENGINEERING | ALL RIGHTS RESERVED | DESIGNED BY ANRIKA
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
