import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Slider from "react-slick";
import axios from "axios";
import "./Life.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BackgroundImg from "../../assets/pupi.jpg";
import AnnouncementCard from "../carrierpage/AnnouncementCard";

const API_URL = process.env.REACT_APP_API_URL;

const EventImageCarousel = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  if (!images || images.length === 0) {
    return null; // Render nothing if there are no images
  }

  const convertBufferToBase64 = (buffer) => {
    const binary = buffer.reduce(
      (acc, byte) => acc + String.fromCharCode(byte),
      ""
    );
    return `data:image/jpeg;base64,${btoa(binary)}`;
  };

  return (
    <Slider {...settings}>
      {images.map((image, index) => (
        <div key={index}>
          <img
            src={convertBufferToBase64(image.image.data)}
            alt={`Event Image ${index + 1}`}
            className="life-event-image"
          />
        </div>
      ))}
    </Slider>
  );
};

const Life = () => {
  const [albums, setAlbums] = useState([]);
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await fetch(`${API_URL}/events`);
        if (response.ok) {
          let data = await response.json();
          // Sort announcements by date and time in descending order
          data = data.sort((a, b) => {
            const dateA = new Date(`${a.date}T${a.time}`);
            const dateB = new Date(`${b.date}T${b.time}`);
            return dateB - dateA; // Most recent first
          });
          setAnnouncements(data);
        } else {
          toast.error("Failed to fetch events.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching events.");
      }
    };

    const fetchAlbumData = async () => {
      try {
        const response = await axios.get(`${API_URL}/albums`);
        const groupedAlbums = groupByCategory(response.data);
        setAlbums(groupedAlbums);
      } catch (error) {
        console.error("Error fetching album data", error);
      }
    };

    fetchAnnouncements();
    fetchAlbumData();
  }, []);

  const groupByCategory = (albums) => {
    const categories = {};
    albums.forEach((album) => {
      if (!categories[album.category]) {
        categories[album.category] = [];
      }
      categories[album.category].push(album);
    });
    return categories;
  };

  return (
    <div className="life-main-div">
      <div
        className="services-container"
        style={{
          backgroundImage: `url(${BackgroundImg})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="services-box">
          <div>
            <p>{/* Home / <span>services</span> */}</p>
            {/* <h1 style={{ fontSize: "34px" }}>
              Elevate Engineering experience with Passion and Innovation
            </h1> */}
          </div>
        </div>
      </div>
      <div className="life-herosection">
        <div className="life-hero-contents">
          <h3 className="life-title3">
            Welcome to Aron Engineering, where every day is an opportunity to
            thrive, create, and innovate
          </h3>
        </div>
      </div>

      <div className="life-events-section">
        <h2 className="life-events-title">Our Events</h2>
        <div className="announcements-section">
          <div className="container">
            <div className="row">
              {announcements.map((announcement) => (
                <div className="col-md-6" key={announcement.id}>
                  <AnnouncementCard announcement={announcement} />
                </div>
              ))}
            </div>
          </div>
        </div>
        {Object.keys(albums).map((category, index) => (
          <div key={index} className="life-event">
            <h3 className="life-event-title">{category}</h3>
            <EventImageCarousel images={albums[category]} />
          </div>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Life;
