import { React, useRef, useEffect } from "react";
import "./servicespage.css";
import BackgroundImg from "../../assets/pupi.jpg";
import StructuralDesign from "../../assets/services-img/structuraldesign.png";
import DeckDesign from "../../assets/services-img/deckdesign.png";
import PatioCover from "../../assets/services-img/patio-coverdesign.png";
import TinyHouse from "../../assets/services-img/tinyhome.png";
import CoveredHall from "../../assets/services-img/coveredhall.png";
import Stadium from "../../assets/services-img/stadium.jpg";
import CommercialStructure from "../../assets/services-img/commercialstructure.jpg";
import ResidentialSturcture from "../../assets/services-img/residentialstructure.png";
import PublicBuilding from "../../assets/services-img/publicbuilding.jpg";
import Landscaping from "../../assets/services-img/landscaping.jpg";
import GeoTechnical from "../../assets/services-img/geotechnicaldesign.jpg";
import UrbanPlanning from "../../assets/services-img/urbanplanning.jpeg";
import Transportation from "../../assets/services-img/transportation.jpeg";
import WaterSupply from "../../assets/services-img/watersupply.jpeg";
import ElectricalWorks from "../../assets/services-img/electricalworks.jpeg";
import Sewage from "../../assets/services-img/sewage.jpeg";
import Irrigation from "../../assets/services-img/irrigation.jpeg";
import RemoteSensing from "../../assets/services-img/remotesensing.jpeg";
import Valuation from "../../assets/services-img/valuation.jpeg";
import SoftwareTraining from "../../assets/services-img/softwaretraining.jpeg";
import feasibility from "../../assets/services-img/Feasibilitystudy.webp";
import rivertraining from "../../assets/services-img/rivertraining.jpg";
import { motion, useAnimation, useTransform, useScroll } from "framer-motion";
import { useInView } from "react-intersection-observer";

const HorizontalScrollPage = () => {
  return (
    <>
      <div
        className="services-container"
        style={{
          backgroundImage: `url(${BackgroundImg})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="services-box">
          <div>
            <p>{/* Home / <span>services</span> */}</p>
            {/* <h1>SERVICES </h1> */}
          </div>
        </div>
      </div>
      <div className="services-page-content">
        <div className="section-title">
          <h1 className="background-title">
            Structural & Architectural Design
          </h1>
          <h2 className="foreground-title">
            Structural & Architectural Design
          </h2>
        </div>
        <div className="services-content-first">
          <div className="first-img">
            <img src={StructuralDesign} alt="structurald design" />
          </div>
          <div className="first-content">
            <p>
              Aron Engineering leads in design innovation with a team of
              structural and geotechnical engineers and architects dedicated to
              transforming vision into reality. Our experts, armed with advanced
              skills and a focus on innovation, integrate modern design
              requirements, global standards, and state-of-the-art tools.
            </p>
            <p>
              In addition to structural engineering, we enhance your projects
              with 2D and 3D architectural services. By bringing design concepts
              to life, we deliver detailed 2D plans and engaging 3D models.
              Experience the future with our realistic renderings, crafted with
              attention to detail, lighting, and spatial design. Our
              walk-throughs and animations offer comprehensive insights, and our
              expertise extends to landscaping and master plans for projects of
              all sizes.
            </p>
            <p>
              At Aron Engineering, compliance with regulations is a promise. Our
              designs are both technologically advanced and artistically
              refined, supported by a skilled team that understands each
              project’s unique demands.
            </p>
            <p>
              For complete structural and architectural solutions, choose Aron
              Engineering to turn your ideas into reality. Contact us for
              innovative engineering solutions.
            </p>
          </div>
        </div>
        <div className="services-content-second">
          <div className="section-title">
            <h1 className="background-title">Services Based On Our Location</h1>
            <h2 className="foreground-title">Services Based On Our Location</h2>
          </div>

          <div className="united-state" style={{ marginTop: "20px" }}>
            <h1>UNITED STATES</h1>
            <div className="united-services-list">
              <div className="service-content">
                <div className="us-servicecard">
                  <div class="overlay"></div>
                  <img className="us-img" src={DeckDesign} alt="deck design" />

                  <div className="textbox">
                    <p className="text">
                      We cover an all-inclusive design package for all types of
                      timber and steel deck designs based on international
                      codes.
                    </p>
                  </div>
                </div>
                <h4 className="service-name">Deck Design</h4>
              </div>

              <div className="service-content">
                <div className="us-servicecard">
                  <div class="overlay"></div>
                  <img className="us-img" src={PatioCover} alt="patio cover" />

                  <div className="textbox">
                    <p className="text">
                      We design multiple kinds of pergolas, gazebo, deck/roof
                      cover and patio cover including mono-slope, gable and hip
                      roof designs.
                    </p>
                  </div>
                </div>
                <h4 className="service-name">
                  Pergola & Patio/Deck CoverDeck Design
                </h4>
              </div>

              <div className="service-content">
                <div className="us-servicecard">
                  <div class="overlay"></div>
                  <img className="us-img" src={TinyHouse} alt="tinyhouse" />

                  <div className="textbox">
                    <p className="text">
                      We also design tiny wooden houses inclusive of shear wall
                      and foundation design along with seismic and wind
                      resistance checks.
                    </p>
                  </div>
                </div>
                <h4 className="service-name">Design of Tiny Homes </h4>
              </div>
            </div>
          </div>

          <div
            className="united-state nepal-service"
            style={{ marginTop: "120px" }}
          >
            <h1>NEPAL</h1>
            <div className="united-services-list">
              <div className="service-content">
                <div className="us-servicecard">
                  <div class="overlay"></div>
                  <img
                    className="us-img"
                    src={CoveredHall}
                    alt="covered hall"
                  />

                  <div className="textbox">
                    <p className="text">
                      We create versatile covered halls with global safety
                      standards and innovative functionality.
                    </p>
                  </div>
                </div>
                <h4 className="service-name">Multi-purpose covered hall</h4>
              </div>

              <div className="service-content">
                <div className="us-servicecard">
                  <div class="overlay"></div>
                  <img className="us-img" src={Stadium} alt="stadium" />

                  <div className="textbox">
                    <p className="text">
                      Cutting-edge designs for stadiums, prioritizing spectator
                      experience, safety, and modern amenities.
                    </p>
                  </div>
                </div>
                <h4 className="service-name">Stadium</h4>
              </div>

              <div className="service-content">
                <div className="us-servicecard">
                  <div class="overlay"></div>
                  <img
                    className="us-img"
                    src={CommercialStructure}
                    alt="commercial structure"
                  />

                  <div className="textbox">
                    <p className="text">
                      Efficient and modern designs for offices, retail, and
                      industrial spaces.
                    </p>
                  </div>
                </div>
                <h4 className="service-name">Commercial Structures</h4>
              </div>

              <div className="service-content" style={{ marginTop: "-80px" }}>
                <div className="us-servicecard">
                  <div class="overlay"></div>
                  <img
                    className="us-img"
                    src={ResidentialSturcture}
                    alt="residential structure"
                  />

                  <div className="textbox">
                    <p className="text">
                      Tailored designs for comfortable and aesthetic homes,
                      apartments, and communities.
                    </p>
                  </div>
                </div>
                <h4 className="service-name">Residential Structures</h4>
              </div>

              <div className="service-content" style={{ marginTop: "-80px" }}>
                <div className="us-servicecard">
                  <div class="overlay"></div>
                  <img
                    className="us-img"
                    src={PublicBuilding}
                    alt="public building"
                  />

                  <div className="textbox">
                    <p className="text">
                      Expert designs for schools, libraries, and community
                      centers, emphasizing accessibility and sustainability.
                    </p>
                  </div>
                </div>
                <h4 className="service-name">Public Buildings</h4>
              </div>

              <div className="service-content" style={{ marginTop: "-80px" }}>
                <div className="us-servicecard">
                  <div class="overlay"></div>
                  <img className="us-img" src={Landscaping} alt="landscaping" />

                  <div className="textbox">
                    <p className="text">
                      Comprehensive designs transforming outdoor spaces into
                      visually appealing environments.
                    </p>
                  </div>
                </div>
                <h4 className="service-name">Landscaping</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="services-content-third">
          <div className="section-title">
            <h1 className="background-title" style={{ marginTop: "10px" }}>
              Other Services
            </h1>
            <h2 className="foreground-title">Other Services </h2>
          </div>

          <motion.div className="other-services-list">
            <motion.div
              className="list-card"
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, threshold: 0.99 }}
              transition={{ duration: 0.3, ease: "easeOut", delay: "0" }}
            >
              <img src={GeoTechnical} alt="geo technical" />
              <h1>01</h1>
              <h4>Geotechnical/ Geological Design</h4>
            </motion.div>

            <motion.div
              className="list-card"
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, threshold: 0.99 }}
              viewport={{ once: true, threshold: 0.99 }}
              transition={{ duration: 0.3, ease: "easeOut", delay: "0.2" }}
            >
              <img src={UrbanPlanning} alt="urban planning" />
              <h1>02</h1>
              <h4>Urban Planning </h4>
            </motion.div>

            <motion.div
              className="list-card"
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, threshold: 0.99 }}
              viewport={{ once: true, threshold: 0.99 }}
              transition={{ duration: 0.3, ease: "easeOut", delay: "0.4" }}
            >
              <img src={Transportation} alt="transportation" />
              <h1>03</h1>
              <h4>Transportation</h4>
            </motion.div>

            <motion.div
              className="list-card"
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, threshold: 0.99 }}
              viewport={{ once: true, threshold: 0.99 }}
              transition={{ duration: 0.3, ease: "easeOut", delay: "0" }}
            >
              <img src={WaterSupply} alt="watersupply" />
              <h1>04</h1>
              <h4>Water Supply & Sanitary Design</h4>
            </motion.div>

            <motion.div
              className="list-card"
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, threshold: 0.99 }}
              viewport={{ once: true, threshold: 0.99 }}
              transition={{ duration: 0.3, ease: "easeOut", delay: "0.2" }}
            >
              <img src={ElectricalWorks} alt="electrical works" />
              <h1>05</h1>
              <h4>Electrical Works </h4>
            </motion.div>

            <motion.div
              className="list-card"
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, threshold: 0.99 }}
              viewport={{ once: true, threshold: 0.99 }}
              transition={{ duration: 0.3, ease: "easeOut", delay: "0.4" }}
            >
              <img src={Sewage} alt="sewage" />
              <h1>06</h1>
              <h4>Sewerage & Land-fill Site Design</h4>
            </motion.div>

            <motion.div
              className="list-card"
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, threshold: 0.99 }}
              viewport={{ once: true, threshold: 0.99 }}
              transition={{ duration: 0.3, ease: "easeOut", delay: "0" }}
            >
              <img src={Irrigation} alt="irrigation" />
              <h1>07</h1>
              <h4>Irrigation</h4>
            </motion.div>

            <motion.div
              className="list-card"
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, threshold: 0.99 }}
              viewport={{ once: true, threshold: 0.99 }}
              transition={{ duration: 0.3, ease: "easeOut", delay: "0.2" }}
            >
              <img src={RemoteSensing} alt="remote sensing" />
              <h1>08</h1>
              <h4>Remote Sensing based survey and mapping </h4>
            </motion.div>

            <motion.div
              className="list-card"
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, threshold: 0.99 }}
              viewport={{ once: true, threshold: 0.99 }}
              transition={{ duration: 0.3, ease: "easeOut", delay: "0.4" }}
            >
              <img src={Valuation} alt="valuation" />
              <h1>09</h1>
              <h4>Valuation </h4>
            </motion.div>

            <motion.div
              className="list-card"
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, threshold: 0.99 }}
              viewport={{ once: true, threshold: 0.99 }}
              transition={{ duration: 0.3, ease: "easeOut", delay: "0" }}
            >
              <img src={feasibility} alt="feasibility study" />
              <h1>10</h1>
              <h4>Feasibility Study </h4>
            </motion.div>

            <motion.div
              className="list-card"
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, threshold: 0.99 }}
              viewport={{ once: true, threshold: 0.99 }}
              transition={{ duration: 0.3, ease: "easeOut", delay: "0.2" }}
            >
              <img src={rivertraining} alt="river training works" />
              <h1>11</h1>
              <h4>River- training works </h4>
            </motion.div>

            <motion.div
              className="list-card"
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, threshold: 0.99 }}
              transition={{ duration: 0.3, ease: "easeOut", delay: "0.4" }}
            >
              <img src={SoftwareTraining} alt="software training" />
              <h1>12</h1>
              <h4>Software trainings </h4>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default HorizontalScrollPage;
