import React, { useRef, useState, useEffect } from "react";
import "./contactpage.css";
import BackgroundImg from "../../assets/pupi.jpg";
import GoToTop from "../GoToTop";
import emailjs from "@emailjs/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faEnvelope,
  faInbox,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

const ContactPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    const inputs = document.querySelectorAll(".contact-input");
    inputs.forEach((ipt) => {
      ipt.addEventListener("focus", () => {
        ipt.parentNode.classList.add("focus");
        ipt.parentNode.classList.add("not-empty");
      });
      ipt.addEventListener("blur", () => {
        if (ipt.value === "") {
          ipt.parentNode.classList.remove("not-empty");
        }
        ipt.parentNode.classList.remove("focus");
      });
    });
  }, []);

  const refForm = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_tyvpa0h",
        "template_05d08u6",
        refForm.current,
        "jNPXXPpRD-oYJIm25"
      )
      .then(
        () => {
          alert("Message successfully sent!");
          window.location.reload(false);
        },
        () => {
          alert("Failed to send the message, please try again");
        }
      );
  };

  return (
    <>
      <div className="contact-contents">
        <div className="contact-container">
          <div
            className="services-container"
            style={{
              backgroundImage: `url(${BackgroundImg})`,
              backgroundSize: "cover",
              backgroundPosition: "bottom",
              backgroundAttachment: "fixed",
            }}
          >
            <div className="services-box">
              <div>
                <p>{/* Home / <span>services</span> */}</p>
                {/* <h1>CONTACT</h1> */}
              </div>
            </div>
          </div>
          <div className="contact-title-2">
            <h1 className="contact-title-header">
              Should you Have any questions, queries or require further
              information ??
              <span className="title-span">
                {""} Please feel free to reach out to us.
              </span>
            </h1>
          </div>
          <div className="contact-content">
            <div className="left-side">
              <div className="map-container">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14129.360227896104!2d85.33366!3d27.706785!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb194eb0dd4203%3A0x9f9d486a258772a7!2sARON%20Engineering%20Pvt.%20Ltd.!5e0!3m2!1sen!2snp!4v1698564257439!5m2!1sen!2snp"
                  width="600"
                  height="500"
                  allowFullScreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  title="Ilami agro and tech"
                ></iframe>
              </div>
            </div>

            <div className="contact-form-container">
              <div className="form-wrapper">
                <form onSubmit={sendEmail} className="contact-form">
                  <div className="input-wrap w-100">
                    <input
                      className="contact-input"
                      autoComplete="off"
                      name="Name"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    <label>Name</label>
                    <FontAwesomeIcon className="icons" icon={faAddressCard} />
                  </div>

                  <div className="input-wrap w-100">
                    <input
                      className="contact-input"
                      autoComplete="off"
                      name="Email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <label>Email</label>
                    <FontAwesomeIcon className="icons" icon={faEnvelope} />
                  </div>

                  <div className="input-wrap w-100">
                    <input
                      name="Subject"
                      autoComplete="off"
                      className="contact-input"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      required
                    />
                    <label>Subject</label>
                    <FontAwesomeIcon className="icons" icon={faPaperPlane} />
                  </div>

                  <div className="input-wrap w-100">
                    <textarea
                      name="Message"
                      autoComplete="off"
                      className="contact-input"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    ></textarea>
                    <label>Message</label>
                    <FontAwesomeIcon className="icons" icon={faInbox} />
                  </div>

                  <button type="submit">Send Message</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GoToTop />
    </>
  );
};

export default ContactPage;
