import React, { useEffect, useState, useRef } from "react";
import "./carrierpage.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AnnouncementCard from "./AnnouncementCard";
import BackgroundImg from "../../assets/pupi.jpg";
import GoToTop from "../GoToTop";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSoccerBall,
  faPerson,
  faBriefcase,
  faGift,
  faBarChart,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

const CarrierPage = () => {
  const openingsSectionRef = useRef(null);

  const [announcements, setAnnouncements] = useState([]);
  const [openings, setOpenings] = useState([]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/events`);
        if (response.ok) {
          const data = await response.json();
          setAnnouncements(data);
        } else {
          toast.error("Failed to fetch events.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching events.");
      }
    };

    const fetchJobs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/jobs`);
        if (response.ok) {
          const data = await response.json();
          const visibleOpenings = data.filter((job) => !job.hide);
          setOpenings(visibleOpenings);
        } else {
          toast.error("Failed to fetch job openings.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching job openings.");
      }
    };

    fetchAnnouncements();
    fetchJobs();
  }, []);

  const handleViewOpeningsClick = () => {
    openingsSectionRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleApplyNowClick = (jobId) => {
    window.location.href = `/job/${jobId}`;
  };

  const iconSize = "2x";
  const isMobile = useMediaQuery({ maxWidth: 464 });

  return (
    <>
      <div className="carrier-page">
        <div
          className="services-container"
          style={{
            backgroundImage: `url(${BackgroundImg})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundAttachment: "fixed",
          }}
        >
          <div className="services-box">
            <div>
              <p>{/* Home / <span>services</span> */}</p>
              {/* <h1>ANNOUNCEMENTS</h1> */}
            </div>
          </div>
        </div>
        <div className="carrier-page-content">
          <div className="carrier-form-header-maindiv">
            {/* <h1 className="carrier-form-header">Empower your career with</h1>
            <h1 className="carrier-form-header2">Aron Engineering</h1> */}
            <div className="carrier-form-about">
              <h2 style={{ marginTop: "40px" }}>
                We're seeking passionate individuals ready to make an impact and
                grow with us. Join our team and shape the future!
              </h2>
              <button
                className="button-carrier"
                onClick={handleViewOpeningsClick}
              >
                View Openings
              </button>
            </div>
          </div>

          <div className="perks-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="perks-title">Perks</h1>
                  <h1 className="perks-subtitle">
                    Elevating Experiences and Exclusive Benefits Await You Here
                  </h1>
                </div>
                <div className="perks-cuntant">
                  <div className="col-md-3">
                    <div className="perks-content">
                      <div className="logo-container">
                        <FontAwesomeIcon icon={faSoccerBall} size={iconSize} />
                      </div>
                      <h2 className="perks-text">
                        Enjoy five workdays, weekends for quality time, and
                        monthly sports activities, balancing productivity with
                        relaxation and recreation.
                      </h2>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="perks-content">
                      <div className="logo-container">
                        <FontAwesomeIcon icon={faPerson} size={iconSize} />
                      </div>
                      <h2 className="perks-text">
                        Our culture values dedication and excellence, offering
                        you the chance to step into leadership roles where your
                        skills can shine.
                      </h2>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="perks-content">
                      <div className="logo-container">
                        <FontAwesomeIcon icon={faGift} size={iconSize} />
                      </div>
                      <h2 className="perks-text">
                        Hard work is rewarded here with appreciation and
                        bonuses, recognizing both individual achievements and
                        team contributions.
                      </h2>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="perks-content">
                      <div className="logo-container">
                        <FontAwesomeIcon icon={faBarChart} size={iconSize} />
                      </div>
                      <h2 className="perks-text">
                        Aron fosters growth, turning every project into an
                        opportunity to enhance skills, advance professionally,
                        and achieve your career goals.
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="openings-section" ref={openingsSectionRef}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="openings-title">Current Openings</h1>
                </div>
                {openings.map((opening) => (
                  <div className="col-md-12" key={opening.id}>
                    <div className="openings-colomn">
                      <div className="first-part">
                        <div className="opening-img-div">
                          <img
                            className="briefcase-img"
                            src="https://www.lftechnology.com/images/icons/briefcase_outline.svg"
                            alt="briefcase"
                          />
                        </div>
                        <div className="openings-texts">
                          <h2 className="openings-name">{opening.title}</h2>
                        </div>
                      </div>
                      <div className="last-part">
                        <h2
                          className="joinnow"
                          onClick={() => handleApplyNowClick(opening.id)}
                        >
                          Apply Now
                        </h2>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <GoToTop />
    </>
  );
};

export default CarrierPage;
