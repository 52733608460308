import Img1 from "../../assets/services-img/structuraldesign.png";
import Img2 from "../../assets/services-img/geotechnicaldesign.jpg";
import Img3 from "../../assets/services-img/urbanplanning.jpeg";
import Img4 from "../../assets/services-img/patio-coverdesign.png";

export const Data = [
  {
    img: Img1,
    title: "Structural & Architectural Design ",
    color: "blue",
    svgPath:
      "M330.255 60.7666C326.932 55.5144 337.675 69.7879 330.255 60.7666C351.337 90.2872 289.433 38.7084 0.411105 75.9999L0 75.9999L390 76L390 -5.74578e-05C389.306 91.9093 291.807 -5.84128e-05 330.255 60.7666Z",
    fillColor: "#3d1b4b",
    viewBox: "0 0 390 70",
  },
  {
    img: Img2,
    title: "Geotechnical Design ",
    color: "green",
    svgPath:
      "M0.00106229 40L0.00106046 1.18084e-05C0.00106046 1.18084e-05 -1.53846 19.4267 112.598 33.6544C159.118 39.4533 113.187 21.0121 173.034 28.4724C226.024 35.0779 254.522 39.1053 285 40L0.00106229 40Z",
    fillColor: "#142f40",
    viewBox: "0 0 279 40",
  },
  {
    img: Img3,
    title: "Urban Planning ",
    color: "blue",
    svgPath:
      "M284.999 40L284.999 -1.51564e-06C284.999 -1.51564e-06 286.538 19.4267 172.402 33.6545C125.882 39.4534 171.813 21.0121 111.966 28.4724C58.9757 35.0779 30.4783 39.1053 -4.55147e-06 40L284.999 40Z",
    fillColor: "#3d1b4b",
    viewBox: "0 0 279 40",
  },
];
