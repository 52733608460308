import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function GoToTop() {
  const routePath = useLocation();

  useEffect(() => {
    // Scroll to the top on initial load and route changes
    window.scrollTo(0, 0);
  }, [routePath]);

  useEffect(() => {
    // Scroll to the top on page reload
    window.scrollTo(0, 0);
  }, []);

  return null;
}
